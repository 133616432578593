<template>
  <div id="app">
    <component :is="headerComponent" />
    <router-view/>
    <component :is="footerComponent" />
    <component :is="cookieComponent" />
  </div>
</template>

<script>
import {EventBus} from "./assets/js/event-bus.js"

import HeaderBar from './components/common/HeaderBar'
import HeaderBarEN from './components/common-en/HeaderBarEN'
import FooterSection from './components/common/FooterSection'
import FooterSectionEN from './components/common-en/FooterSectionEN'
import Cookie from './components/common/Cookie'
import CookieEN from './components/common-en/CookieEN'

export default {
  name: 'App',
  components: {
    HeaderBar,
    HeaderBarEN,
    FooterSection,
    FooterSectionEN,
    Cookie,
    CookieEN
  },
  data() {
    return {
      language: 'cn',
      headerComponent: HeaderBar,
      footerComponent: FooterSection,
      cookieComponent: Cookie
    }
  },
  created() {
    this.updateLanguage()
    EventBus.$on('updateLanguage', () => {
      this.updateLanguage()
    })
  },
  mounted() {
  },
  methods: {
    updateLanguage() {
      this.language = this.$cookies.get('language') === 'en' ? 'en' : 'cn'
      this.headerComponent = this.language === 'cn' ? HeaderBar : HeaderBarEN
      this.footerComponent = this.language === 'cn' ? FooterSection : FooterSectionEN
      this.cookieComponent = this.language === 'cn' ? Cookie : CookieEN
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media screen and (max-width: 767px){
      padding-top: 68px;
    }
    padding-top: 80px;
  }
}
</style>

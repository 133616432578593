import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import * as InterfaceApi from './assets/js/api.js'


document.title = '点金贵金属-Point Gold'

Vue.config.productionTip = false
const favicon = require('@/assets/images/logo.png')
const faviconLink = document.createElement('link')
faviconLink.rel = 'icon'
faviconLink.href = favicon
document.head.appendChild(faviconLink)
Vue.use(ElementUI)
Vue.use(VueCookies)

Vue.prototype.$EventBus = new Vue()

Vue.prototype.$api = InterfaceApi
Vue.prototype.msgSuccess = function (msg, size = 'small') {
  this.$message({ showClose: true, message: msg, type: 'success', customClass: `message-${size}` })
}

Vue.prototype.msgError = function (msg, size = 'small') {
  this.$message({ showClose: true, message: msg, type: 'error', customClass: `message-${size}` })
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg)
}

new Vue({
  render: h => h(App),
  router
}).$mount('#app')

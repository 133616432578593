import Vue from 'vue';
import Router from 'vue-router';
import { EventBus } from '../assets/js/event-bus';

const NotFound = resolve => {require(['@/components/common/Error404.vue'], resolve)};
const Home = resolve => {require(['@/components/pages/Home.vue'], resolve)};
const Announcement = resolve => {require(['@/components/pages/Announcement'], resolve)};
const Details = resolve => {require(['@/components/pages/Details.vue'], resolve)};
const Details404 = resolve => {require(['@/components/pages/Details404.vue'], resolve)};
const TalentPlan = resolve => {require(['@/components/pages/TalentPlan.vue'], resolve)};
const CareerDevelopment = resolve => {require(['@/components/pages/CareerDevelopment.vue'], resolve)};
const EmployeeCare = resolve => {require(['@/components/pages/EmployeeCare.vue'], resolve)};
const CompanyProfile = resolve => {require(['@/components/pages/CompanyProfile.vue'], resolve)};
const Honor = resolve => {require(['@/components/pages/Honor.vue'], resolve)};
const CorporateNews = resolve => {require(['@/components/pages/CorporateNews.vue'], resolve)};
const NewDetails = resolve => {require(['@/components/pages/NewDetails.vue'], resolve)};
const NewDetails404 = resolve => {require(['@/components/pages/NewDetails404.vue'], resolve)};
const ContactUs = resolve => {require(['@/components/pages/ContactUs.vue'], resolve)};
const DevelopmentPath = resolve => {require(['@/components/pages/DevelopmentPath.vue'], resolve)};
const Jewelry = resolve => {require(['@/components/pages/Jewelry.vue'], resolve)};
const Bullion = resolve => {require(['@/components/pages/Bullion.vue'], resolve)};
const MaterialProduct = resolve => {require(['@/components/pages/MaterialProduct.vue'], resolve)};
const RecyclingRefining = resolve => {require(['@/components/pages/RecyclingRefining.vue'], resolve)};
const Analyze = resolve => {require(['@/components/pages/Analyze.vue'], resolve)};
const JinMingFengJewelry = resolve => {require(['@/components/pages/JinMingFengJewelry.vue'], resolve)};
const MidasRefining = resolve => {require(['@/components/pages/MidasRefining.vue'], resolve)};
const MidianNewMaterials = resolve => {require(['@/components/pages/MidianNewMaterials.vue'], resolve)};

const HomeEN = resolve => {require(['@/components/pages-en/HomeEN.vue'], resolve)};
const JinMingFengJewelryEN = resolve => {require(['@/components/pages-en/JinMingFengJewelryEN.vue'], resolve)};
const MidasRefiningEN = resolve => {require(['@/components/pages-en/MidasRefiningEN.vue'], resolve)};
const MidianNewMaterialsEN = resolve => {require(['@/components/pages-en/MidianNewMaterialsEN.vue'], resolve)};
const JewelryEN = resolve => {require(['@/components/pages-en/JewelryEN.vue'], resolve)};
const BullionEN = resolve => {require(['@/components/pages-en/BullionEN.vue'], resolve)};
const MaterialProductEN = resolve => {require(['@/components/pages-en/MaterialProductEN.vue'], resolve)};
const RecyclingRefiningEN = resolve => {require(['@/components/pages-en/RecyclingRefiningEN.vue'], resolve)};
const AnalyzeEN = resolve => {require(['@/components/pages-en/AnalyzeEN.vue'], resolve)};
const AnnouncementEN = resolve => {require(['@/components/pages-en/AnnouncementEN.vue'], resolve)};
const DetailsEN = resolve => {require(['@/components/pages-en/DetailsEN.vue'], resolve)};
const Details404EN = resolve => {require(['@/components/pages-en/Details404EN.vue'], resolve)};
const TalentPlanEN = resolve => {require(['@/components/pages-en/TalentPlanEN.vue'], resolve)};
const CareerDevelopmentEN = resolve => {require(['@/components/pages-en/CareerDevelopmentEN.vue'], resolve)};
const EmployeeCareEN = resolve => {require(['@/components/pages-en/EmployeeCareEN.vue'], resolve)};
const CompanyProfileEN = resolve => {require(['@/components/pages-en/CompanyProfileEN.vue'], resolve)};
const DevelopmentPathEN = resolve => {require(['@/components/pages-en/DevelopmentPathEN.vue'], resolve)};
const HonorEN = resolve => {require(['@/components/pages-en/HonorEN.vue'], resolve)};
const CorporateNewsEN = resolve => {require(['@/components/pages-en/CorporateNewsEN.vue'], resolve)};
const ContactUsEN = resolve => {require(['@/components/pages-en/ContactUsEN.vue'], resolve)};
const NewDetailsEN = resolve => {require(['@/components/pages-en/NewDetailsEN.vue'], resolve)};

Vue.use(Router);
const router = new Router({
    routes: [
        {path: '*', redirect: '/404'},
        {path: '/404', name: 'error404', component: NotFound},
        {path: '/', name: 'home', component: Home},
        {path: '/announcement', name: 'announcement', component: Announcement},
        {path: '/details/:id', name: 'details', component: Details},
        {path: '/details404', name: 'details404', component: Details404},
        {path: '/talentPlan', name: 'talentPlan', component: TalentPlan},
        {path: '/careerDevelopment', name: 'careerDevelopment', component: CareerDevelopment},
        {path: '/employeeCare', name: 'employeeCare', component: EmployeeCare},
        {path: '/companyProfile', name: 'companyProfile', component: CompanyProfile},
        {path: '/honor', name: 'honor', component: Honor},
        {path: '/corporateNews', name: 'corporateNews', component: CorporateNews},
        {path: '/newDetails/:id', name: 'newDetails', component: NewDetails},
        {path: '/newDetails404', name: 'newDetails404', component: NewDetails404},
        {path: '/contactUs', name: 'contactUs', component: ContactUs},
        {path: '/developmentPath', name: 'developmentPath', component: DevelopmentPath},
        {path: '/jewelry', name: 'jewelry', component: Jewelry},
        {path: '/bullion', name: 'bullion', component: Bullion},
        {path: '/materialProduct', name: 'materialProduct', component: MaterialProduct},
        {path: '/recyclingRefining', name: 'recyclingRefining', component: RecyclingRefining},
        {path: '/analyze', name: 'analyze', component: Analyze},
        {path: '/jinMingFengJewelry', name: 'jinMingFengJewelry', component: JinMingFengJewelry},
        {path: '/midasRefining', name: 'midasRefining', component: MidasRefining},
        {path: '/midianNewMaterials', name: 'midianNewMaterials', component: MidianNewMaterials},

        {path: '/en/', name: 'homeEN', component: HomeEN},
        {path: '/en/jinMingFengJewelry', name: 'jinMingFengJewelryEN', component: JinMingFengJewelryEN},
        {path: '/en/midasRefining', name: 'midasRefiningEN', component: MidasRefiningEN},
        {path: '/en/midianNewMaterials', name: 'midianNewMaterialsEN', component: MidianNewMaterialsEN},
        {path: '/en/Jewelry', name: 'JewelryEN', component: JewelryEN},
        {path: '/en/bullion', name: 'bullionEN', component: BullionEN},
        {path: '/en/materialProduct', name: 'materialProductEN', component: MaterialProductEN},
        {path: '/en/recyclingRefining', name: 'recyclingRefiningEN', component: RecyclingRefiningEN},
        {path: '/en/analyze', name: 'analyzeEN', component: AnalyzeEN},
        {path: '/en/announcement', name: 'announcementEN', component: AnnouncementEN},
        {path: '/en/details/:id', name: 'detailsEN', component: DetailsEN},
        {path: '/en/details404', name: 'details404EN', component: Details404EN},
        {path: '/en/talentPlan', name: 'talentPlanEN', component: TalentPlanEN},
        {path: '/en/careerDevelopment', name: 'careerDevelopmentEN', component: CareerDevelopmentEN},
        {path: '/en/employeeCare', name: 'employeeCareEN', component: EmployeeCareEN},
        {path: '/en/companyProfile', name: 'companyProfileEN', component: CompanyProfileEN},
        {path: '/en/developmentPath', name: 'developmentPathEN', component: DevelopmentPathEN},
        {path: '/en/honor', name: 'honorEN', component: HonorEN},
        {path: '/en/corporateNews', name: 'corporateNewsEN', component: CorporateNewsEN},
        {path: '/en/contactUs', name: 'contactUsEN', component: ContactUsEN},
        {path: '/en/newDetails/:id', name: 'newDetailsEN', component: NewDetailsEN}
    ]
})
// 全局导航守卫
router.beforeEach((to, from, next) => {
    const app = router.app;
    if (app && app.$children) {
      // 查找 HeaderBar 组件实例并调用 closeAllSubMenus 方法
      const headerBar = app.$children.find(child => child.$options.name === 'headerBar');
      console.log(app.$children)
      console.log(headerBar)
      if (headerBar) {
        headerBar.$nextTick(() => {
          headerBar.closeAllSubMenus();
        });
      }
    }
    next();
  });

  // 全局导航守卫
    router.beforeEach((to, from, next) => {
        EventBus.$emit('closeAllSubMenus');
        next();
    });

export default router;
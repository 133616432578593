// api.js
import axios from 'axios'

const instance = axios.create({
    baseURL: "https://pg.idjyj.com",
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json'
    }
})

export const requestAction = async (options) => {
    options.headers = {
        ...options.headers
    }
    const response = await instance(options)
    const responseData = response.data
    return responseData
}

export const news = data => {
    const options = {
        url: '/website/v1/website/news',
        method: 'POST',
        data: data
    }
    return requestAction(options)
}

export const newsTag = data => {
    const options = {
        url: '/website/v1/website/news-tag',
        method: 'POST',
        data: data
    }
    return requestAction(options)
}

export const newsId = data => {
    const options = {
        url: '/website/v1/website/news-id',
        method: 'POST',
        data: data
    }
    return requestAction(options)
}
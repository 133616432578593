<template>
  <div id="cookie">
    <div class="cookie-div" v-if="cookieDivOr">
      <div>本网站使用Cookie在您的计算机。</div>
      <button @click="closeDiv">确 定</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookie",
  data() {
    return {
      cookieDivOr: true
    }
  },
  created() {
    this.checkCookie()
  },
  methods: {
    checkCookie: function () {
      this.cookieDivOr = this.$cookies.get('pop-up') !== 'false'
    },
    closeDiv () {
      this.$cookies.set('pop-up', 'false', 60 * 60 * 24 *30)
      this.cookieDivOr= false
    }
  }
}
</script>

<style scoped lang="scss">
#cookie {
  .cookie-div {
    border-radius: 5px;
    background-color: #4A2D85;
    height: 14vw;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 14vw;
    @media only screen and (max-width: 767px) {
      width: 100%;
      bottom: 0;
      right: 0;
      height: 30vw;
    }
    z-index: 9999;
    div {
      padding: 20% 10%;
      color: #ffffff;
      font-weight: bolder;
      font-size: 18px;
      @media only screen and (max-width: 767px) {
        padding: 5% 10%;
      }
    }
    button {
      margin-bottom: 5%;
      margin-left: 10%;
      padding: 5% 20%;
      font-size: 18px;
      color: #4A2D85;
      border-radius: 5px;
      border: none;
      @media only screen and (max-width: 767px) {
        padding: 2% 10%;
      }
    }
  }
}
</style>